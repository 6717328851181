import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useField } from 'react-final-form';
import { Map } from 'immutable';
import { Renderer, defaultBlocks } from '../../../lib/draftjs-editor';
import { getCredentialMinimums } from '../../../advance-application/advance-selectors';
import {
  CredentialRow,
  CredentialLabel,
  CredentialQuantity,
  CategoryHeader,
} from './CredentialRequestsSection.styles';
import PropTypes from 'prop-types';
import moment from 'moment';
import FormField from '../../forms/FormField';
import FormSection from '../../forms/FormSection';
import ReduxFormsField from '../../forms/ReduxFormsField';
import QuantityField from '../../forms/QuantityField';
import InputScaffoldNoLabel from '../../forms/InputScaffoldNoLabel';

const draftRenderer = new Renderer(defaultBlocks);

const CredentialRequestsSection = ({ className, section, credentials }) => {
  const credentialMinimums = useSelector(getCredentialMinimums);

  const field = useField('credentials', {
    validate: value =>
      !value || !Object.values(value).some(qty => qty > 0)
        ? 'Must select at least one credential'
        : undefined,
  });

  const getCredentialOption = credential => {
    const credentialType = credential.get('credentialType');

    const periodName =
      credentialType.get('issue_frequency') === 'ONE_TIME'
        ? credential
            .get('oneTimePeriods')
            .map(period => period.get('name'))
            .join(', ')
        : credential.getIn(['period', 'name']);

    const date =
      credentialType.get('issue_frequency') === 'DAILY'
        ? credential.getIn(['date', 'date'])
        : null;

    const label = (
      <span>
        <span style={{ marginLeft: '10px', fontWeight: '700' }}>
          {credentialType.get('name')}
        </span>
        <span style={{ marginLeft: '10px' }}>{periodName}</span>
        {date && (
          <span style={{ marginLeft: '5px', fontSize: 'smaller' }}>
            ({moment.utc(date).format('ddd, MMM DD')})
          </span>
        )}
      </span>
    );

    return {
      label,
      value: String(credential.get('id')),
    };
  };

  const credentialOptionsByCategory = useMemo(() => {
    const categoryGroups = credentials
      .filter(credential => !credential.get('_pivot_is_add_on'))
      .groupBy(credential => credential.getIn(['credentialType', 'category']))
      .sortBy(category => category.get('name'));
    const optionsByCategory = [];
    categoryGroups.forEach((credentials, category) => {
      const creds = [];
      if (category.get('name').toUpperCase().startsWith('SHOW - W')) {
        if (
          credentials.find(credential =>
            credential
              .get('credentialType')
              .get('name')
              .toUpperCase()
              .startsWith('EVENT STAFF'),
          )
        ) {
          creds.push(
            credentials.find(credential =>
              credential
                .get('credentialType')
                .get('name')
                .toUpperCase()
                .startsWith('EVENT STAFF'),
            ),
          );
        }
        if (
          credentials.find(credential =>
            credential
              .get('credentialType')
              .get('name')
              .toUpperCase()
              .startsWith('STAFF'),
          )
        ) {
          creds.push(
            credentials.find(credential =>
              credential
                .get('credentialType')
                .get('name')
                .toUpperCase()
                .startsWith('STAFF'),
            ),
          );
        }
        if (
          credentials.find(credential =>
            credential
              .get('credentialType')
              .get('name')
              .toUpperCase()
              .startsWith('CREW'),
          )
        ) {
          creds.push(
            credentials.find(credential =>
              credential
                .get('credentialType')
                .get('name')
                .toUpperCase()
                .startsWith('CREW'),
            ),
          );
        }
        if (
          credentials.find(credential =>
            credential
              .get('credentialType')
              .get('name')
              .toUpperCase()
              .startsWith('PRODUCTION'),
          )
        ) {
          creds.push(
            credentials.find(credential =>
              credential
                .get('credentialType')
                .get('name')
                .toUpperCase()
                .startsWith('PRODUCTION'),
            ),
          );
        }
        if (
          credentials.find(credential =>
            credential
              .get('credentialType')
              .get('name')
              .toUpperCase()
              .startsWith('ALL ACCESS'),
          )
        ) {
          creds.push(
            credentials.find(credential =>
              credential
                .get('credentialType')
                .get('name')
                .toUpperCase()
                .startsWith('ALL ACCESS'),
            ),
          );
        }
      }

      optionsByCategory.push({
        id: category.get('id'),
        name: category.get('name').toUpperCase(),
        color: category.get('color'),
        credentials:
          creds.length > 0
            ? creds.map(getCredentialOption)
            : credentials.map(getCredentialOption),
      });
    });
    let otionsByCategoryOrdered = [];
    if (optionsByCategory.find(option => option.name.startsWith('LOAD IN - W')))
      otionsByCategoryOrdered.push(
        optionsByCategory.find(option => option.name.startsWith('LOAD IN - W')),
      );
    if (optionsByCategory.find(option => option.name.startsWith('LOAD IN - V')))
      otionsByCategoryOrdered.push(
        optionsByCategory.find(option => option.name.startsWith('LOAD IN - V')),
      );
    if (optionsByCategory.find(option => option.name.startsWith('SHOW - W')))
      otionsByCategoryOrdered.push(
        optionsByCategory.find(option => option.name.startsWith('SHOW - W')),
      );
    if (optionsByCategory.find(option => option.name.startsWith('SHOW - S')))
      otionsByCategoryOrdered.push(
        optionsByCategory.find(option => option.name.startsWith('SHOW - S')),
      );
    if (optionsByCategory.find(option => option.name.startsWith('SHOW - V')))
      otionsByCategoryOrdered.push(
        optionsByCategory.find(option => option.name.startsWith('SHOW - V')),
      );

    otionsByCategoryOrdered = otionsByCategoryOrdered.concat(
      optionsByCategory.filter(option => {
        return (
          !option.name.startsWith('LOAD IN - W') &&
          !option.name.startsWith('LOAD IN - V') &&
          !option.name.startsWith('SHOW - W') &&
          !option.name.startsWith('SHOW - S') &&
          !option.name.startsWith('SHOW - V')
        );
      }),
    );

    return otionsByCategoryOrdered.length > 0
      ? otionsByCategoryOrdered
      : optionsByCategory;
  }, [credentials]);

  let updatedDesc = draftRenderer.convertRawToHTML(
    section.get('content').toJS(),
  );

  return (
    <section className={className}>
      <header>
        <div className={`${className}--title`}>Staff Credentials</div>
      </header>

      <article>
        <div className={`${className}--desc`}>
          <div
            className="draftjs-output"
            dangerouslySetInnerHTML={{ __html: updatedDesc }}
          />
        </div>
        <InputScaffoldNoLabel
          validation={field.meta.touched && field.meta.error}
        >
          <FormSection name="credentials">
            {credentialOptionsByCategory.map(category => (
              <div key={category.id}>
                <CategoryHeader>
                  <span
                    className="credential-category-color"
                    style={{ backgroundColor: `${category.color}` }}
                  />
                  <span>{category.name}</span>
                </CategoryHeader>
                {category.credentials.map((credential, index) => (
                  <CredentialRow key={index}>
                    <CredentialLabel>{credential.label}</CredentialLabel>
                    {
                      <CredentialQuantity>
                        <FormField
                          required
                          component={ReduxFormsField}
                          name={`c_${credential.value}`}
                        >
                          <QuantityField
                            min={credentialMinimums.get(credential.value) ?? 0}
                          />
                        </FormField>
                      </CredentialQuantity>
                    }
                  </CredentialRow>
                ))}
              </div>
            ))}
          </FormSection>
        </InputScaffoldNoLabel>
      </article>
    </section>
  );
};

CredentialRequestsSection.propTypes = {
  className: PropTypes.string.isRequired,
  section: PropTypes.instanceOf(Map).isRequired,
  credentials: PropTypes.object.isRequired,
};

export default CredentialRequestsSection;
