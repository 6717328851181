import { Checkbox as SynfrastructureCheckbox } from 'synfrastructure';
import PropTypes from 'prop-types';
import ToggleScaffold from '../../forms/ToggleScaffold';
import translateErrors from '../translateErrors';

const Checkbox = ({ field, onChange }) => (
  <ToggleScaffold
    label={field.get('label')}
    validation={translateErrors(field.getIn(['error', 'value']))}
    required={!!field.get('required')}
    description={field.get('description')}
  >
    <SynfrastructureCheckbox
      id="value"
      name="value"
      checked={!!field.get('value') || false}
      onChange={e => onChange(field.set('value', e.target.checked))}
    />
  </ToggleScaffold>
);

Checkbox.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
};

export default Checkbox;
