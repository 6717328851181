import PropTypes from 'prop-types';
import InputScaffold from '../../forms/InputScaffold';
import translateErrors from '../translateErrors';

const Select = ({ field, onChange }) => (
  <InputScaffold
    label={field.get('label')}
    validation={translateErrors(field.getIn(['error', 'value']))}
    required={!!field.get('required')}
    description={field.get('description')}
  >
    <select
      value={field.get('value') || ''}
      onChange={e => onChange(field.set('value', e.target.value))}
    >
      {(field.get('options') || ['']).map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  </InputScaffold>
);

Select.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
};

export default Select;
