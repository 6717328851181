import { useState } from 'react';
import { SingleDatePicker } from 'react-dates-gte-react-17';
import { ICON_AFTER_POSITION } from 'react-dates-gte-react-17/constants';
import translateErrors from '../translateErrors';
import moment from 'moment';
import PropTypes from 'prop-types';
import InputScaffold from '../../forms/InputScaffold';
import Icon from '../../icons/Icon';

const Date = ({ event, field, onBlur, onChange }) => {
  const [focused, setFocused] = useState(false);

  return (
    <InputScaffold
      label={field.get('label')}
      validation={translateErrors(field.getIn(['error', 'value']))}
      required={!!field.get('required')}
      description={field.get('description')}
    >
      <SingleDatePicker
        date={field.get('value') ? moment.utc(field.get('value')) : null}
        onDateChange={date => onChange(field.set('value', date?.toISOString()))}
        focused={focused}
        onFocusChange={({ focused }) => {
          setFocused(focused);
          if (!focused) onBlur();
        }}
        noBorder
        withPortal
        showClearDate
        customInputIcon={<Icon icon="Calendar" />}
        inputIconPosition={ICON_AFTER_POSITION}
        displayFormat="MM/DD/YYYY"
        numberOfMonths={1}
        transitionDuration={0}
        initialVisibleMonth={() =>
          field.get('value')
            ? moment.utc(field.get('value'))
            : moment.utc(
                event
                  .get('periods')
                  .sortBy(period => period.get('start_date'))
                  .getIn([0, 'dates', 0, 'date']),
              )
        }
      />
    </InputScaffold>
  );
};

Date.propTypes = {
  event: PropTypes.object,
  field: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export default Date;
