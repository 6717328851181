import PropTypes from 'prop-types';
import InputScaffold from '../../forms/InputScaffold';
import translateErrors from '../translateErrors';

const Number = ({ field, onBlur, onChange }) => (
  <InputScaffold
    label={field.get('label')}
    validation={translateErrors(field.getIn(['error', 'value']))}
    required={!!field.get('required')}
    description={field.get('description')}
  >
    <input
      type="number"
      name="value"
      value={field.get('value') || ''}
      onBlur={onBlur}
      onChange={e => onChange(field.set('value', e.target.value))}
    />
  </InputScaffold>
);

Number.propTypes = {
  field: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export default Number;
